
import { getDashboardPath } from '@/api/flowSession';
import { actualUrl } from '@/api/instance';
import BaseCirclePreloader from '@/components/baseComponents/BaseCirclePreloader.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    BaseCirclePreloader,
  },
})

export default class PortalPage extends Vue {
  $refs!: {
    iframe: HTMLIFrameElement;
  };

  dashBoardUrl = ''

  get companyName(): string {
    return this.$store.state.user.companyName;
  }
  get firstName(): string {
    return this.$store.state.user.firstName;
  }
  get lastName(): string {
    return this.$store.state.user.lastName;
  }

  async initializeDashboard(): Promise<void> {
    await this.getDashboardUrl();
    this.checkPortalPageVisited();
  }

  async getDashboardUrl(): Promise<void> {
    const { data } = await getDashboardPath();
    const link = `${actualUrl()}admin/${data}`;
    let flowName = this.$store.getters['flowStages/getFlowName'];

    switch (flowName) {
      case 'cash-management':
        flowName = 'bank-accounts';
        break;
      case 'business360':
        flowName = '';
        break;
      default:
    }

    this.dashBoardUrl = flowName ? `${link}/${flowName}` : link;
  }

  checkPortalPageVisited(): void {
    if (this.$store.state.user.isPortalPageVisited) {
      this.$store.commit('flowStages/resetFlowVersion');
    }
    this.$store.commit('user/setUserWasOnPortalPage');
  }

  setIframeHeight(event: any): void {
    const layoutElement = document.querySelector('.main-layout');
    const headerElement = document.querySelector('.header');

    if (event.data.action === 'changeHeight') {
      const { height } = event.data;
      this.$refs.iframe.style.height = typeof height === 'number' ? `${height}px` : height;

      if (layoutElement) {
        layoutElement.scrollIntoView({ block: 'start' });
      }
    }
    
    if (event.data.action === 'created' && this.$refs.iframe.contentWindow) {
      this.$refs.iframe.contentWindow.postMessage({ action: 'disableScroll' }, '*');
    }

    if (event.data.action === 'modalOpened' && headerElement) {
      const centerPosition = (this.$refs.iframe.offsetHeight / 2) - this.$refs.iframe.offsetTop - headerElement.clientHeight;
      window.scrollTo({
        top: centerPosition,
        behavior: "smooth"
      })
    }
  }

  mounted(): void {
    this.initializeDashboard();
    window.addEventListener('message', this.setIframeHeight);
  }
  destroyed(): void {
    window.removeEventListener('message', this.setIframeHeight);
  }
}
